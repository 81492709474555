/* Sakura.css v1.0.0
 * ================
 * Minimal css theme.
 * Project: https://github.com/oxalorg/sakura
 */
/* 
   With personal modifications. -Bilange 
*/


// polar night
$nord0: #2E3440 !default;
$nord1: #3B4252 !default;
$nord2: #434C5E !default;
$nord3: #4C566A !default;

// snow storm
$nord4: #D8DEE9 !default;
$nord5: #E5E9F0 !default;
$nord6: #ECEFF4 !default;

// frost
$nord7: #8FBCBB !default;
$nord8: #88C0D0 !default;
$nord9: #81A1C1 !default;
$nord10: #5E81AC !default;

// aurora
$nord11: #BF616A !default;
$nord12: #D08770 !default;
$nord13: #EBCB8B !default;
$nord14: #A3BE8C !default;
$nord15: #B48EAD !default;

@media (prefers-color-scheme: dark)  {
	body { background-color: $nord0; color: $nord6; }
	hr { border-color: $nord10; }
	pre, code {background-color: $nord1;} 
	td, th { border-bottom: 1px solid $nord1; }

	a, a:link { color: $nord9; }
	a:hover { color: $nord13; }

	.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
		background-color: $nord9;
		color: $nord0;
		border: 1px solid $nord9;
	}

	textarea, select, input[type] {
		color: $nord4;
		background-color: $nord1;
		border: 1px solid $nord10;
	}
	textarea:focus, select:focus, input[type]:focus {
		border: 1px solid $nord8;
	}
	.button:focus, .button:hover, button:focus, button:hover, input[type="submit"]:focus, input[type="submit"]:hover, input[type="reset"]:focus, input[type="reset"]:hover, input[type="button"]:focus, input[type="button"]:hover {
	    background-color: $nord3;
	    border-color: $nord1;
	    color: $nord4;
	    outline: 0; 
	}
	input[type="checkbox"]:focus { outline: 1px dotted $nord10; }

	div.callout {
		border: 2px solid $nord10; 
		background-color: $nord3;
	}
	div.callout.info { border: 2px solid $nord13; }
	div.callout.warn { border: 2px solid $nord12; }
	div.callout.caution { border: 2px solid $nord11; }

	/* rouge syntax highlighting - code dark */
	.highlight, .highlight .w {
	  color: #D8DEE9;
	  background-color: #4C566A;
	}
	.highlight .err {
	  color: #BF616A;
	  background-color: #4C566A;
	}
	.highlight .gi {
	  color: #A3BE8C;
	  background-color: #4C566A;
	}
	.highlight .gd {
	  color: #BF616A;
	  background-color: #4C566A;
	}
	.highlight .o, .highlight .ow {
	  color: #D8DEE9;
	}
	.highlight .p, .highlight .pi {
	  color: #D8DEE9;
	}
	.highlight table td { padding: 5px; }
	.highlight table pre { margin: 0; }

	.highlight .err {
	  font-weight: bold;
	}
	.highlight .c, .highlight .ch, .highlight .cd, .highlight .cm, .highlight .cpf, .highlight .c1, .highlight .cs {
	  color: #D08770;
	  font-style: italic;
	}
	.highlight .cp {
	  color: #A3BE8C;
	}
	.highlight .nt {
	  color: #BF616A;
	}
	.highlight .gh {
	  color: #A3BE8C;
	  font-weight: bold;
	}
	.highlight .k, .highlight .kn, .highlight .kp, .highlight .kr, .highlight .kv {
	  color: #BF616A;
	}
	.highlight .kc {
	  color: #B48EAD;
	}
	.highlight .kt {
	  color: #fabd2f;
	}
	.highlight .kd {
	  color: #BF616A;
	}
	.highlight .s, .highlight .sb, .highlight .sc, .highlight .dl, .highlight .sd, .highlight .s2, .highlight .sh, .highlight .sx, .highlight .s1 {
	  color: #A3BE8C;
	  font-style: italic;
	}
	.highlight .si {
	  color: #A3BE8C;
	  font-style: italic;
	}
	.highlight .sr {
	  color: #A3BE8C;
	  font-style: italic;
	}
	.highlight .sa {
	  color: #BF616A;
	}
	.highlight .se {
	  color: #BF616A;
	}
	.highlight .nn {
	  color: #A3BE8C;
	}
	.highlight .nc {
	  color: #A3BE8C;
	}
	.highlight .no {
	  color: #B48EAD;
	}
	.highlight .na {
	  color: #A3BE8C;
	}
	.highlight .m, .highlight .mb, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mx {
	  color: #B48EAD;
	}
	.highlight .ss {
	  color: #8FBCBB;
	}
}

@media (prefers-color-scheme: light) {
	body { background-color: $nord6; color: $nord1; }
	hr { border-color: $nord10; }
	pre, code {background-color: $nord4;} 
	td, th { border-bottom: 1px solid $nord4; }

	a, a:link { color: #4B81F4; }
	a:hover { color: $nord7; }

	.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
		background-color: $nord4;
		color: $nord1;
		border: 1px solid $nord3;
	}
	textarea, select, input[type] {
		color: $nord1;
		background-color: $nord4;
		border: 1px solid $nord10;
	}
	textarea:focus, select:focus, input[type]:focus {
		border: 1px solid $nord8;
	}

	.button:focus, .button:hover, button:focus, button:hover, input[type="submit"]:focus, input[type="submit"]:hover, input[type="reset"]:focus, input[type="reset"]:hover, input[type="button"]:focus, input[type="button"]:hover {
	    background-color: $nord12;
	    border-color: $nord1;
	    color: $nord4;
	    outline: 0; 
	}
	input[type="checkbox"]:focus { outline: 1px dotted $nord10; }

	div.callout {
		border: 2px solid $nord3; 
		background-color: $nord4;
	}

	div.callout.info { border: 2px solid $nord13; }
	div.callout.warn { border: 2px solid $nord12; }
	div.callout.caution { border: 2px solid $nord11; }

	/* rouge syntax highlighting - code light */
	.highlight table td { padding: 5px; }
	.highlight table pre { margin: 0; }
	.highlight, .highlight .w {
	  color: #586e75;
	}
	.highlight .err {
	  color: #002b36;
	  background-color: #dc322f;
	}
	.highlight .c, .highlight .ch, .highlight .cd, .highlight .cm, .highlight .cpf, .highlight .c1, .highlight .cs {
	  color: #657b83;
	}
	.highlight .cp {
	  color: #b58900;
	}
	.highlight .nt {
	  color: #b58900;
	}
	.highlight .o, .highlight .ow {
	  color: #93a1a1;
	}
	.highlight .p, .highlight .pi {
	  color: #93a1a1;
	}
	.highlight .gi {
	  color: #859900;
	}
	.highlight .gd {
	  color: #dc322f;
	}
	.highlight .gh {
	  color: #268bd2;
	  background-color: #002b36;
	  font-weight: bold;
	}
	.highlight .k, .highlight .kn, .highlight .kp, .highlight .kr, .highlight .kv {
	  color: #6c71c4;
	}
	.highlight .kc {
	  color: #cb4b16;
	}
	.highlight .kt {
	  color: #cb4b16;
	}
	.highlight .kd {
	  color: #cb4b16;
	}
	.highlight .s, .highlight .sb, .highlight .sc, .highlight .dl, .highlight .sd, .highlight .s2, .highlight .sh, .highlight .sx, .highlight .s1 {
	  color: #859900;
	}
	.highlight .sa {
	  color: #6c71c4;
	}
	.highlight .sr {
	  color: #2aa198;
	}
	.highlight .si {
	  color: #d33682;
	}
	.highlight .se {
	  color: #d33682;
	}
	.highlight .nn {
	  color: #b58900;
	}
	.highlight .nc {
	  color: #b58900;
	}
	.highlight .no {
	  color: #b58900;
	}
	.highlight .na {
	  color: #268bd2;
	}
	.highlight .m, .highlight .mb, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mx {
	  color: #859900;
	}
	.highlight .ss {
	  color: #859900;
	}
}

/* rouge syntax highlighting - code commun */

/* fonts */
/* vietnamese */
@font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inconsolata Regular'), local('Inconsolata-Regular'),
    url(/assets/fonts/Inconsolata-Regular.woff2) format('woff2'), url(/assets/fonts/Inconsolata-Regular.woff) format('woff'),url(/assets/fonts/Inconsolata-Regular.ttf) format('truetype') ;
    /* src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url(QldKNThLqRwH-OJ1UHjlKGlW5qhWxg.woff2) format('woff2'); */
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inconsolata Regular'), local('Inconsolata-Regular'),
    url(/assets/fonts/Inconsolata-Regular.woff2) format('woff2'), url(/assets/fonts/Inconsolata-Regular.woff) format('woff'),url(/assets/fonts/Inconsolata-Regular.ttf) format('truetype') ;
    /*url(QldKNThLqRwH-OJ1UHjlKGlX5qhWxg.woff2) format('woff2');*/
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inconsolata Regular'), local('Inconsolata-Regular'),
    url(/assets/fonts/Inconsolata-Regular.woff2) format('woff2'), url(/assets/fonts/Inconsolata-Regular.woff) format('woff'),url(/assets/fonts/Inconsolata-Regular.ttf) format('truetype') ;    
    /* url(QldKNThLqRwH-OJ1UHjlKGlZ5qg.woff2) format('woff2'); */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("/assets/fonts/Montserrat-Regular.woff2") format("woff2"),
         url("/assets/fonts/Montserrat-Regular.woff") format("woff");
}



/* Body */
html {
    font-size: 62.5%;
    font-family: 'Montserrat', serif;
}

body {
    font-size: 1.8rem;
    line-height: 1.618;
    /* max-width: 38em; */
    /* max-width: 60em; */
    margin: auto;
    padding: 13px;
}


@media (min-width: 900px) {
    body {
	width: 900px;
    }
}

@media (min-width: 684px) and (max-width: 899px) {
    body {
	width: 600px;
    }
}

@media (max-width: 684px) {
    body {
	font-size: 1.53rem;
	width: 90%;
    }
}

@media (max-width: 382px) {
    body {
	font-size: 1.35rem;
	width: 100%;
    }
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    /* font-family: 'Playfair Display', Verdana, Geneva, sans-serif; */
    font-weight: 500;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

h1 {
    font-size: 2em;
    font-weight: 700;
}
h1 .subtitle {
    font-size: 80%;
    font-weight: normal;
}

h2 { font-size: 1.75em; }

h3 { font-size: 1.5em; }

h4 { font-size: 1.3em; }

h5 { font-size: 1.25em; }

h6 { font-size: 1em; }

small, sub, sup { font-size: 75%; }


a { text-decoration: none; }

ul { padding-left: 1.4em; }

li { margin-bottom: 0.4em; }

div.callout {
    font-style: normal;
}

div.callout div.title {
    margin: 0.5em;
    font-weight: bold;
    font-size: 110%;
}

div.callout blockquote {
    background-color: transparent;
    border: 0px solid transparent; 
    font-style: normal;
    margin: 0.5em;
    padding: 0;
}

blockquote {
    font-style: italic;
    margin-left: 1.5em;
    padding-left: 1em;
    border-left: 3px solid #28d; }


img { max-width: 100%; }

/* Pre and Code */
pre, code {
    font-family: Inconsolata;
}
pre {
    display: block;
    padding: 1em;
    overflow-x: auto;
}

code {
    font-size: 0.9em;
    padding: 0 0.5em;
    white-space: pre-wrap;
}

pre > code {
    padding: 0;
    background-color: transparent;
    white-space: pre;
}

/* Tables */
table {
  text-align: justify;
  width: 100%;
  border-collapse: collapse; 
}

td, th {
  padding: 0.5em;
}

textarea { width: 100%; }

.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 1px;
  cursor: pointer;
  box-sizing: border-box; 
}

.button[disabled], button[disabled], input[type="submit"][disabled], input[type="reset"][disabled], input[type="button"][disabled] {
    cursor: default;
    opacity: .5; 
}


textarea, select, input[type] {
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box; 
}
textarea:focus, select:focus, input[type]:focus {
    outline: 0; 
}


label, legend, fieldset {
    display: block;
    margin-bottom: .5rem;
    font-weight: 600;
}

.smaller {
    font-size: 75%;
}

abbr {cursor: help;}
a abbr {cursor: pointer;}

.post-content img { margin: auto; display: block; }
